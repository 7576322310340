<template>
	<v-dialog :value="true" min-width="300" max-width="350" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>Reset Password</v-card-title>
			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>
				<v-text-field v-model="email" label="Email Address" :disabled="saving" required hide-details dense outlined></v-text-field>

				<v-text-field dense hide-details outlined class="my-3"
							  v-model="password"
							  :append-icon="show1 ? 'fad fa-eye' : 'fad fa-eye-slash'"
							  :rules="[v => !!v || 'field is required']"
							  :type="show1 ? 'text' : 'password'"
							  label="Password"
							  @click:append="show1 = !show1"></v-text-field>

				<v-text-field dense hide-details outlined class="my-3"
							  v-model="password_confirm"
							  :append-icon="show2 ? 'fad fa-eye' : 'fad fa-eye-slash'"
							  :rules="[(password === password_confirm) || 'Password must match']"
							  :type="show2 ? 'text' : 'password'"
							  label="Verify Password"
							  @click:append="show2 = !show2"></v-text-field>
			</v-card-text>
			<v-card-actions>
				<v-btn :disabled="saving || !valid" :loading="saving" color="primary" @click="processForm" class="ml-auto">Submit</v-btn>
				<v-btn @click="modal.trigger('close')" :disabled="saving">Cancel</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "resetPassword",

		props: {
			modal: {
				type: Object,
				required: true,
			},
		},

		data() {
			return {
				email: "",
				password: "",
				password_confirm: "",

				show1: false,
				show2: false,
				rules: {
					required: (value) => !!value || "Required.",
					min: (v) => v.length >= 6 || "Min 6 characters",
					emailMatch: () =>
						`The email and password you entered don't match`,
				},

				modalErrorMessage: false,
				saving: false,
			};
		},

		computed: {
			valid() {
				return (
					this.email.length > 5 &&
					this.password &&
					this.password === this.password_confirm
				);
			},
			modalTitle() {
				return this.title;
			},

			formModel() {
				let model = {
					email: this.email,
					password: this.password,
					password_confirm: this.password_confirm,
				};

				return model;
			},
		},

		methods: {
			processForm() {
				this.saving = true;

				let api = `/api/user/reset-password/${this.email}`;

				this.$xhrRequest.send("put", api, this.formModel).then(
					(response) => {
						this.saving = false;
						if (response == false) {
							this.$swal({
								title: "Oops!",
								text:
									"Your request could not be processed at this time.\n\nPlease try again later.",
								icon: "error",
							});
							return;
						}
						this.$swal({
							title: "Request Submitted",
							text: "Your password reset request has been submitted.",
							icon: "success",
						}).then(() => {
							this.modal.trigger("save close", response);
						});
					},
					(xhr) => {
						this.saving = false;
						let errors = this.$insight.helpers.getRequestErrors(xhr);
						errors = this.$insight.helpers.flattenRequestErrors(errors);

						this.modalErrorMessage = errors.join("<br>");
						this.$snotify.error("Error!");
					}
				);
			},
		},
	};
</script>